import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "tag"
    }}>{`Tag`}</h2>
    <ComponentCode name="Tag" component="tag" variation="tag" hasReactVersion="tag--default" hasVueVersion="tag--default" hasAngularVersion="?path=/story/tag--basic" codepen="zXqoLz" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "tag-filter"
    }}>{`Tag filter`}</h2>
    <ComponentCode name="Tag filter" component="tag" variation="tag--filter" hasReactVersion="tag--filter" codepen="XQdNPg" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="tag" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      